import React from 'react';

// React
import { Disclosure } from '@headlessui/react'

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";

// Icons
import { ChevronDownIcon } from '@heroicons/react/outline'

// SVG
import AppleLogo from '../../images/features/mobile-app/apple.inline.svg'
import PlayLogo from '../../images/features/mobile-app/google-play.inline.svg'


// Images
import HeaderImg from '../../images/features/mobile-app/header.png';
import ControlPng from '../../images/features/mobile-app/control.png';
import CrossPlatformPng from '../../images/features/mobile-app/cross-platform.png';
import ReactNativePng from '../../images/features/mobile-app/react-native.png';
import MobileExample from '../../images/features/testing-utility/the-convenience.png'
import { Link } from 'gatsby';



const faqs = [
    {
        question: "How do I know if my central station is compatible with Tech Tester?",
        answer:
            `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
    },
    {
        question: "What do I need to setup an account with Tech Tester?",
        answer:
            "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
    },
    {
        question: "I use multiple central stations. Does Tech Tester allow that?",
        answer:
            "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
    },
    {
        question: "My free trial has expired, how do I continue to use Tech Tester?",
        answer:
            "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
    },
    {
        question: "When will my credit card be billed for Tech Tester?",
        answer:
            "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
    },
    {
        question: "I would like one of my technicians to have full access. Is that possible?",
        answer:
            "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MobileApp() {

    return (
        <div>
            <Seo title="Mobile App" description="Learn about the Tech Tester mobile app and its features." />
            <Header/>
            {/* Hero section */}
            <div className="bg-myGray-300">
                <div className={"py-24 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                    <div className={"my-auto text-left "}>
                        <h1 className={"md:text-4xl text-3xl text-myGray-600 font-bold"}>An application to solve problems</h1>
                        <h3 className={"text-myGray-600 text-lg pt-10"}>Tech Tester is always available on the web, but you can take it wherever you go with Tech Tester Mobile for iOS and Android.</h3> <p className={'pt-4 text-lg'}>Download the app today to optimize your workflows and empower your technicians.</p>
                        <Link to="/contact">
                            <button  className="mt-10 inline-flex px-4 py-2 text-base border border-transparent rounded-md shadow-sm text-sm text-black font-bold bg-yellowTT hover:bg-yellowTT-alt"
                            >START NOW</button></Link>
                    </div>
                    <div className={"m-auto"}>
                        <img
                            className=""
                            src={HeaderImg}
                            alt="Temp Item"
                        />
                    </div>
                </div>
            </div>



            {/* Alternating Feature Sections */}
            <div className="relative overflow-hidden">
                <div aria-hidden="true" className="absolute z-10 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>

                <div className="relative z-20 my-24">
                    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
                        <h2 className="pb-20 text-3xl font-bold">Built to keep you in control</h2>
                        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={ReactNativePng} className={'h-40 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Team Management
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    Manage your team efficiently and effectively - at home, at work, or on-the-go                               </dd>
                            </div>
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={ControlPng} className={'h-40 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Control
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    You have complete control. Set your team member's access levels, dates and times
                                </dd>
                            </div>
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={CrossPlatformPng} className={'h-40 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Cross Platform
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    The app is available for you any way that you want to access it
                                </dd>
                            </div>
                        </dl>
                    </div>
                    {/*
                     <div className={'m-auto pt-10'} id={"phone-full-animation"}>
                        <PhoneFullAnimation />
                    </div>

                     */}

                </div>

                <div className="relative mt-24 py-24 bg-myGray-200">
                    <div className="relative z-10 grid grid-cols-1 text-center place-items-center">

                        <div className="mt-6 p-10">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                Keep everyone in the loop
                            </h2>
                            <p className="mt-4 text-lg text-gray-500 max-w-5xl">
                                Utilize push notifications for messaging, assigning work queue items, and notifications of expiring test periods!                           </p>
                        </div>
                    </div>
                </div>

                <div className="mt-18">
                    <div
                        className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div
                            className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        The app you need
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Our application empowers your team by giving them the tools they need to service your accounts with ease. Our testing utility lets the user easily see what sensors have reported, what has been restored, and what is still unrestored. They can then send a report summary to your office that shows that all sensors have reported and restored before they leave the site.
                                    </p>
                                    <div className="grid grid-cols-2 mt-20 ">
                                        <div className={""}>
                                            <Link
                                                to="/contact"
                                                className="items-center w-50 h-16 mr-5 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                                            >
                                                <AppleLogo className={"w-7 h-7 fill-white mr-2"} />
                                                Coming Soon!
                                            </Link>
                                            {/*<p className={"ml-3 pt-2 text-gray-500"}>4.56 out of 1938 Ratings</p>*/}
                                            {/*<img className={"ml-10 w-20 h-auto"} src={StarRating}/>*/}
                                        </div>
                                        <div>
                                            <Link
                                                to="/contact"
                                                className="items-center w-50 h-16 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                                            >
                                                <PlayLogo className={"w-7 h-7 fill-white mr-2"} />
                                                Coming Soon!
                                            </Link>
                                            {/*<p className={"ml-16 pt-2 text-gray-500"}>NEW!</p>*/}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-auto">
                            <div className="pl-4 md:mr-6 lg:-mr-16 sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className=""
                                    src={MobileExample}
                                    alt="Temp Item"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}

                <div className="bg-white mt-12">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6 bg-myGray-300 rounded-md m-5 p-5">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}